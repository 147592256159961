// TODO build consistent style for buttons
import { initializeApp } from "firebase/app";
import * as uuid from "uuid";
import {
  getAuth,
  Auth,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {
  Database,
  getDatabase,
  ref as dbRef,
  set as dbSet,
} from "firebase/database";
import {
  FirebaseStorage,
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
} from "firebase/storage";
import { User } from "firebase/auth";
import { useCallback, useEffect, useState } from "react";

import {
  Box,
  Button,
  Center,
  ChakraProvider,
  IconButton,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spinner,
  VStack,
  Text,
  SimpleGrid,
  Flex,
  Spacer,
  Input,
  FormControl,
  FormLabel,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Modal,
  ModalContent,
  ModalFooter,
  Progress,
  useToast,
} from "@chakra-ui/react";

import {
  FaPlay,
  FaPause,
  FaStepBackward,
  FaStepForward,
  FaFastBackward,
  FaFastForward,
} from "react-icons/fa";

import { Podcast, PodcastList } from "./PodcastList";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCGyFKOGgEMqwlPHz-y9Ssd48pAUEUYCVo",
  authDomain: "shauncast-65435.firebaseapp.com",
  databaseURL:
    "https://shauncast-65435-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "shauncast-65435",
  storageBucket: "shauncast-65435.appspot.com",
  messagingSenderId: "509269137790",
  appId: "1:509269137790:web:8aa63618e8fe46658ffb2f",
};

function login(auth: Auth) {
  if (!auth.currentUser) {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).catch((err) => {
      console.log(err, "Error during sign in");
    });
  }
}

function Player(params: { db: Database; storage: FirebaseStorage }) {
  const { db, storage } = params;
  // currently playing audio
  const [podcast, setPodcast] = useState(undefined as Podcast | undefined);
  const [time, setTime] = useState(0);
  const [maxTime, setMaxTime] = useState(1);

  const [playbackRate, setPlaybackRate] = useState(1.0);

  // scrolling through audio pauses. This checks whether to resume playback.
  const [wasPlaying, setWasPlaying] = useState(false);
  const [paused, setPaused] = useState(true);

  const onPlay = useCallback((podcast: Podcast) => {
    setPodcast(podcast);
    setPaused(false);
    setPlaybackRate(podcast.audio?.playbackRate ?? 1.0);
  }, []);
  const onTimeUpdate = useCallback((e: Event) => {
    const audio = e.target as HTMLAudioElement;
    // this is a tad inefficient. If maxtime is used anywhere else this could get nasty
    // setting maxtime (even if it hasn't changed) causes a rewrite
    setMaxTime(audio.duration);
    setTime(audio.currentTime);
  }, []);

  // These are all terrible since I think they regenerate whenever podcast gets changed
  const onChangeStart = useCallback(
    (val: number) => {
      if (podcast?.audio) {
        setWasPlaying(!podcast.audio.paused);
        podcast.audio.pause();
        setPaused(true);
        // podcast.audio.currentTime = val;
      }
    },
    [podcast]
  );
  // this gets called before onChangeStart
  const onChange = useCallback(
    (val: number) => {
      // this is kinda hacky
      // since slider is in controlled mode changes shouldn't be possible
      // onChange modifies the currentTime which modifies the time state
      if (podcast?.audio) {
        podcast.audio.currentTime = val;
        setTime(val);
      }
    },
    [podcast]
  );
  const onChangeEnd = useCallback(
    (val: number) => {
      if (podcast?.audio) {
        podcast.audio.currentTime = val;
        if (wasPlaying) {
          podcast.audio.play();
          setWasPlaying(false);
          setPaused(false);
        }
      }
    },
    [podcast, wasPlaying]
  );

  const slowDown = useCallback(() => {
    if (podcast?.audio) {
      podcast.audio.playbackRate -= 0.1;
      setPlaybackRate(podcast.audio.playbackRate);
    }
  }, [podcast]);
  const jumpBack = useCallback(() => {
    if (podcast?.audio) {
      podcast.audio.currentTime -= 10;
    }
  }, [podcast]);
  const toggle = useCallback(() => {
    if (podcast?.audio?.paused) {
      podcast.audio.play();
      setPaused(false);
    } else {
      podcast?.audio?.pause();
      setPaused(true);
    }
  }, [podcast]);
  const jumpForward = useCallback(() => {
    if (podcast?.audio) {
      podcast.audio.currentTime += 10;
    }
  }, [podcast]);
  const speedUp = useCallback(() => {
    if (podcast?.audio) {
      podcast.audio.playbackRate += 0.1;
      setPlaybackRate(podcast.audio.playbackRate);
    }
  }, [podcast]);

  return (
    <div>
      <Center>
        <Box w={{ sm: "100vw", md: "68vw" }}>
          <PodcastList
            db={db}
            storage={storage}
            onPlay={onPlay}
            onTimeUpdate={onTimeUpdate}
          />
        </Box>
      </Center>
      <Box
        zIndex="docked"
        padding="1rem"
        position="sticky"
        bottom="0"
        h="9rem"
        backgroundColor="white"
        borderTopWidth="1px"
      >
        <VStack>
          <Slider
            value={time} // value is set so the slider is "controlled"
            min={0}
            max={maxTime}
            onChangeStart={onChangeStart}
            onChange={onChange}
            onChangeEnd={onChangeEnd}
            focusThumbOnChange={false}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
          <Flex w="100%">
            <Text>{podcast?.title}</Text>
            <Spacer />
            <Box>
              <Text>
                {new Date(time * 1000).toISOString().substr(11, 8)} /{" "}
                {new Date(maxTime * 1000).toISOString().substr(11, 8)}
              </Text>
              <Text>{playbackRate.toFixed(2)}x</Text>
            </Box>
          </Flex>
          <SimpleGrid columns={5} gap="1rem">
            <IconButton
              borderRadius={0}
              aria-label="Decrease speed by 0.1"
              icon={<FaFastBackward />}
              onClick={slowDown}
            />
            <IconButton
              borderRadius={0}
              aria-label="Back 10 seconds"
              icon={<FaStepBackward />}
              onClick={jumpBack}
            />
            <IconButton
              borderRadius={0}
              aria-label="Toggle play pause"
              icon={paused ? <FaPlay /> : <FaPause />}
              onClick={toggle}
            />
            <IconButton
              borderRadius={0}
              aria-label="Forward 10 seconds"
              icon={<FaStepForward />}
              onClick={jumpForward}
            />
            <IconButton
              borderRadius={0}
              aria-label="Increase speed by 0.1"
              icon={<FaFastForward />}
              onClick={speedUp}
            />
          </SimpleGrid>
        </VStack>
      </Box>
    </div>
  );
}

function Upload({ db, storage }: { db: Database; storage: FirebaseStorage }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const toast = useToast();

  const onUpload = useCallback(async () => {
    // upload file with a uuid name
    const titleUpload = (
      document.getElementById("titleUpload") as HTMLInputElement
    ).value;
    const dateUpload = (
      document.getElementById("dateUpload") as HTMLInputElement
    ).valueAsNumber;
    const file = (document.getElementById("fileUpload") as HTMLInputElement)
      .files?.[0];
    if (!titleUpload || isNaN(dateUpload) || !file) {
      return;
    }
    const id = uuid.v4();
    const podcastRef = storageRef(
      storage,
      `podcasts/${id}.${file.name.split(".").pop()}`
    );
    setIsUploading(true);

    // done in one shot instead of allowing pausing
    const uploadTask = uploadBytesResumable(podcastRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setUploadProgress(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (error) => {
        toast({
          title: `Upload error ${error.code}`,
          description: error.message,
          status: "error",
        });
        setIsUploading(false);
      },
      () => {
        // finished successfully
        // create metadata in storage
        const dateString = new Date(dateUpload).toISOString();

        dbSet(dbRef(db, `podcasts/${id}`), {
          title: titleUpload,
          audioPath: podcastRef.fullPath,
          dateString: dateString,
        });

        toast({
          title: "Upload successful",
          description: `"${titleUpload}" was uploaded`,
          status: "success",
        });
        setIsOpen(false);
      }
    );
  }, [db, storage, toast]);

  return (
    <>
      <Button
        zIndex="docked"
        position="fixed"
        top={0}
        left={0}
        borderRadius={0}
        colorScheme="teal"
        onClick={() => setIsOpen(true)}
      >
        Upload
      </Button>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload a new podcast</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isRequired id="titleUpload">
              <FormLabel>Title</FormLabel>
              <Input />
            </FormControl>
            <FormControl isRequired id="dateUpload">
              <FormLabel>Date</FormLabel>
              <Input type="datetime-local" />
            </FormControl>
            <FormControl isRequired id="fileUpload">
              <FormLabel>Upload new podcast</FormLabel>
              <Input type="file" />
            </FormControl>
            <Progress hidden={!isUploading} value={uploadProgress} />
          </ModalBody>
          <ModalFooter>
            <Button borderRadius={0} colorScheme="teal" onClick={onUpload}>
              Upload
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

function App() {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getDatabase(app);
  const storage = getStorage(app);
  const [state, setState] = useState("loading");

  const [user, setUser] = useState(null as User | null);

  useEffect(() => {
    return auth.onAuthStateChanged((param) => {
      if (param) {
        setUser(param);
        console.log(`Hello ${user ? user.displayName : "stranger"}`);
        setState("main");
      } else {
        setState("login");
      }
    });
  });

  let content: JSX.Element = (
    <Box>
      <Upload db={db} storage={storage} />
      <Player db={db} storage={storage} />
    </Box>
  );

  switch (state) {
    case "loading":
      content = (
        <Center width="100vw" height="100vh">
          <Spinner />
        </Center>
      );
      break;
    case "login":
      content = (
        <Center width="100vw" height="100vh">
          <Button onClick={() => login(auth)}>Log in to Google</Button>
        </Center>
      );
  }
  return <ChakraProvider>{content}</ChakraProvider>;
}

export default App;
