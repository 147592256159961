import {
  Database,
  ref as dbRef,
  onValue,
  set as dbSet,
} from "firebase/database";
import * as uuid from "uuid";
import {
  FirebaseStorage,
  ref as storageRef,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";
import { MouseEventHandler, useCallback, useEffect, useState } from "react";

import {
  Box,
  Heading,
  StackDivider,
  Text,
  VStack,
  Image,
  Spinner,
  Divider,
  useToast,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  SimpleGrid,
} from "@chakra-ui/react";
import moment from "moment";
import { FaEdit } from "react-icons/fa";

interface PodcastMeta {
  title: string;
  audioPath: string;
  description?: string;
  imagePath?: string;
  dateString?: string;
}

export interface Podcast extends PodcastMeta {
  id: string;
  title: string;
  date: moment.Moment;
  description?: string;
  audio: HTMLAudioElement;
  imageSrc?: string;
  element?: JSX.Element;
}

/*
 * Okay don't store any state within the PodcastCard as all data needs to be shared
 * with the Podcast list which determines which audio is playing
 * PodcastCard should just stand there and look pretty
 */
function PodcastCard({
  podcast,
  onClick,
  onEdit,
}: {
  podcast: Podcast;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onEdit?: MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <Box
      position="relative"
      cursor="pointer"
      w="100%"
      p={8}
      shadow="md"
      borderWidth="1px"
    >
      <IconButton
        position="absolute"
        top={0}
        right={0}
        borderRadius={0}
        aria-label="Edit card"
        icon={<FaEdit />}
        onClick={onEdit}
      />
      <SimpleGrid
        templateColumns={podcast.imageSrc ? "8rem 1fr" : "0 1fr"}
        w="100%"
        onClick={onClick}
      >
        {podcast.imageSrc ? (
          <Image
            marginInlineEnd="1rem"
            marginBlock="auto"
            boxSize="7rem"
            objectFit="cover"
            borderRadius="full"
            src={podcast.imageSrc}
          />
        ) : (
          <Box></Box>
        )}
        <Box as="span" flexGrow={1}>
          <Heading>{podcast.title}</Heading>
          <Divider />
          <Text fontSize="small">
            {podcast.date &&
              podcast.date.calendar({
                sameElse: "DD/MM/YYYY",
              })}
          </Text>
          <Text>{podcast.description}</Text>
        </Box>
      </SimpleGrid>
    </Box>
  );
}

function EditModal({
  db,
  storage,
  podcast,
  isOpen,
  onClose,
}: {
  db: Database;
  storage: FirebaseStorage;
  podcast?: Podcast;
  isOpen: boolean;
  onClose: () => void;
}) {
  const onClick = useCallback(async () => {
    if (!podcast) {
      return;
    }

    const file = (document.getElementById("imageEdit") as HTMLInputElement)
      .files?.[0];
    if (file) {
      const id = uuid.v4();
      const imageRef = storageRef(
        storage,
        `images/${id}.${file.name.split(".").pop()}`
      );
      await uploadBytes(imageRef, file);
      podcast.imagePath = imageRef.fullPath;
    }

    dbSet(dbRef(db, `podcasts/${podcast.id}`), {
      title: (document.getElementById("titleEdit") as HTMLInputElement).value,
      audioPath: podcast.audioPath,
      description: (
        document.getElementById("descriptionEdit") as HTMLInputElement
      ).value,
      imagePath: podcast.imagePath ?? "",
      dateString: podcast.dateString ?? "",
    } as PodcastMeta);

    onClose();
  }, [db, storage, podcast, onClose]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Editting "{podcast?.title}"</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isRequired id="titleEdit">
            <FormLabel>Title</FormLabel>
            <Input defaultValue={podcast?.title} />
          </FormControl>
          <FormControl id="descriptionEdit">
            <FormLabel>Description</FormLabel>
            <Textarea defaultValue={podcast?.description} />
          </FormControl>
          <FormControl isRequired id="imageEdit">
            <FormLabel>Upload new image</FormLabel>
            <Input type="file" />
          </FormControl>
          <Text fontSize="small">
            Once a field is set it can't be removed (I'm lazy)
          </Text>
          <Text fontSize="small">
            If you need to change the date. Please contact the site admin :)
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button borderRadius={0} colorScheme="teal" onClick={onClick}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

/**
 * Container of audio files
 * onPlay: whenever audio starts playing (from any of the audios)
 * onPause: when all audio has stopped
 */
export function PodcastList(props: {
  db: Database;
  storage: FirebaseStorage;
  onPlay?: (podcast: Podcast) => void;
  onTimeUpdate?: (e: Event) => void;
}) {
  const { db, storage, onPlay, onTimeUpdate } = props;

  const [podcastList, setPodcastList] = useState([] as Podcast[]);
  const [playingIndex, setPlayingIndex] = useState(-1);

  const [modalOpen, setModalOpen] = useState(false);
  const [edittedPodcast, setEdittedPodcast] = useState(
    undefined as Podcast | undefined
  );

  const toast = useToast();

  // grab podcasts and parse then convert to element
  useEffect(() => {
    const listRef = dbRef(db, "podcasts/");
    return onValue(listRef, async (snapshot) => {
      const podcastsObj = snapshot.val() as Record<
        string,
        Partial<PodcastMeta>
      >;

      const list = await Promise.all(
        Object.keys(podcastsObj).map(async (key): Promise<Podcast> => {
          const {
            title = "NO TITLE",
            audioPath,
            description,
            imagePath,
            dateString,
          } = podcastsObj[key];
          if (!audioPath) {
            toast({
              title: "Unexpected error",
              description: `Podcast ${key} does not have a path`,
              status: "error",
            });
            throw new TypeError();
          }

          let imageSrc: string | undefined;
          if (imagePath) {
            try {
              imageSrc = await getDownloadURL(storageRef(storage, imagePath));
              // imgSrc = "https://i.redd.it/u8z9ym7xqgb01.png";
            } catch (err) {
              toast({
                title: "Fetch error",
                description: `Unable to retrieve podcast image for "${title}".`,
                status: "error",
              });
              imageSrc = undefined;
            }
          }
          // create empty audio file. Add the event listeners but don't add the url so it won't preload
          const audio = new Audio();
          audio.addEventListener("timeupdate", (e: Event) => {
            localStorage.setItem(
              `${key}.currentTime`,
              (e.target as HTMLAudioElement).currentTime.toString()
            );
            onTimeUpdate?.(e);
          });
          // if date isn't defined default to now
          return {
            id: key,
            title,
            audioPath,
            description,
            dateString,
            date: moment.utc(dateString),
            imagePath,
            imageSrc,
            audio,
          };
        })
      );

      // sort in reverse order (descending)
      list.sort((a, b) => b.date.unix() - a.date.unix());
      setPodcastList(list);
    });
  }, [toast, db, storage, onTimeUpdate]);

  if (podcastList.length === 0) {
    return (
      <div>
        <div>No podcasts found</div>
        <div>
          <Spinner />
        </div>
      </div>
    );
  }
  return (
    <>
      <VStack divider={<StackDivider />} spacing={8}>
        {podcastList.map((podcast, index) => {
          return (
            <PodcastCard
              key={podcast.id}
              podcast={podcast}
              // onClick can't be made into a callback due to the index
              onClick={async () => {
                if (index !== playingIndex) {
                  // new audio should start playing
                  if (playingIndex >= 0) {
                    podcastList[playingIndex].audio?.pause();
                  }
                  if (!podcast.audio.src) {
                    // attempt to grab audio
                    try {
                      // const url =
                      //   "https://upload.wikimedia.org/wikipedia/commons/7/74/%22Goin%27_Home%22%2C_performed_by_the_United_States_Air_Force_Band.oga";
                      podcast.audio.src = await getDownloadURL(
                        storageRef(storage, podcast.audioPath)
                      );
                      // check if time is saved
                      const t = localStorage.getItem(
                        `${podcast.id}.currentTime`
                      );
                      if (t) {
                        podcast.audio.currentTime = parseFloat(t);
                      }
                    } catch (err) {
                      toast({
                        title: "Fetch error",
                        description: `Unable to access podcast "${podcast.title}". Likely an authorization issue.`,
                        status: "error",
                      });
                      throw err;
                    }
                  }
                  podcast.audio?.play();
                  setPlayingIndex(index);
                  onPlay?.(podcast);
                }
              }}
              onEdit={async () => {
                // open modal
                setModalOpen(true);
                // update modal with the podcast key
                setEdittedPodcast(podcast);
              }}
            />
          );
        })}
      </VStack>
      <EditModal
        db={db}
        storage={storage}
        podcast={edittedPodcast}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
    // create 1 modal and update which podcast you're editting via the podcast key
  );
}
